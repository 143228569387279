@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.ItemDataWrrapper {
  width: 490px;
  padding: 32px;
  margin-bottom: 20px;
  background-color: $color-white;
  border-radius: 10px;
  box-shadow: 0 21px 64px 0 rgba(56, 61, 72, 0.05);
  margin-bottom: 20px;
  &_iconWrapper {
    width: 100px;
    height: 95px;
    margin-bottom: 25px;
    svg {
      width: 100px;
      height: 95px;
    }
  }
  &_title {
    margin-top: 0;
    margin-bottom: 24px;
    font-size: $font-size-xl;
    line-height: 1.43;
    font-weight: $font-bold;
  }
  &_list {
    list-style-type: disc;
    padding-left: 18px;
    margin: 0;
  }
}
