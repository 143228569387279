@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.LoadGroupItem {
  font-size: $font-size-s;
  font-weight: normal;
  width: 100%;
  padding: 18px 0;

  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  column-gap: 20px;

  > div:not(:first-child) {
    text-align: end;
  }
}
