@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.CardItem{
  margin-left: 10px;
  margin-right: 10px;
  width: 22.5%;
  height: 156px;
  padding: 24px;
  background-color: $color-white;
  box-shadow: 0 21px 64px 0 rgba(56, 61, 72, 0.05);
  border-radius: 10px;

  &_iconWrapper{
    width: 60px;
    height: 60px;
    margin-bottom: 24px;
  }
  &_text{
    font-size: $font-size-s;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5; 
  }
}
