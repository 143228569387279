@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.ContainersList {
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px;

  &_List {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 25px;
    row-gap: 35px;
    margin-top: 24px;
  }

  &_Error {
    display: flex;
    align-items: center;

    &Text {
      color: $color-primary-150;
      font-size: $font-size-m;
      font-weight: $font-bold;
      margin-left: 8px;
      word-break: break-word;
    }
  }
}
