@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.HomePageLanding {
  margin-top: 120px;
  padding-top: 180px;
  background-color: $color-additional-light-grey-2;
  &_Heading {
    margin-bottom: 180px;
    margin-top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 1015px;
    font-size: $font-size-xl;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
  }
}
