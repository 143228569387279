@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.AddButton {
  font-weight: $font-bolder;
  &_Icon {
    > :first-child {
      width: 16px;
      height: 16px;
    }
  }
}
