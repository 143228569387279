@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.ColorsBar {
  display: flex;
  flex-direction: column;
  font-size: $font-size-xs;
  font-weight: $font-bolder;
  color: $color-dark-blue;

  &_Row {
    display: flex;
    justify-content: space-between;
  }

  &_Bar {
    border-radius: 2px;
    border: solid 1px $color-additional-light-grey-1;
    background-color: $color-bar-bck;
    height: 6px;
    margin: 7px 0 23px;
    display: flex;

    &Item {
      background-color: var(--loadGroup-color-variable);
      width: var(--colorBar-width-variable);
      height: 100%;
      position: relative;

      &:hover {
        cursor: pointer;
      }

      &_Tooltip {
        display: none;
        min-width: 100px;
        bottom: calc(100% + 10px);
        left: calc(var(--colorBar-width-variable) / 2 - 50px);
        cursor: pointer;
        font-size: $font-size-xxs;
        color: $color-dark-blue;
        font-weight: $font-bold;
        box-sizing: border-box;

        .ColorsBar_BarItem:hover,
        .ColorsBar_BarItem__Hovered & {
          display: block;
        }
      }
    }
  }
}
