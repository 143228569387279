@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.TextArea {
  position: relative;

  .TextArea_Native {
    display: block;

    box-sizing: border-box;
    width: 326px;
    min-width: 190px;
    margin: 0;
    height: 48px;
    min-height: 32px;

    background-image: url(../../assets/images/icon-textArea.svg);
    background-repeat: no-repeat;
    background-position: right 0 bottom 0;

    -webkit-appearance: none;
    -moz-appearance: none;

    resize: vertical;

    &::-webkit-resizer {
      display: none;
    }
  }
}
