@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.CreateLoadBar {
  display: flex;
  justify-content: center;
  width: 100%;
  position: fixed;
  height: 61px;
  margin-top: 64px;
  margin-bottom: 22px;

  &__Scrolled {
    background-color: $color-white;
    z-index: 8;
    margin-top: 0px;
    height: 56px;
    box-shadow: 0 4px 9px 2px rgba(112, 124, 149, 0.13);
  }

  &_Content {
    display: flex;
    color: $color-dark-blue;
    justify-content: space-between;
    align-items: flex-end;

    width: 1130px;

    &__Scrolled {
      align-items: center;
      > div:first-child {
        margin-right: 263px;
      }
    }
  }

  &_Title {
    font-size: $font-size-xl;

    &__Scrolled {
      font-size: $font-size-m;
    }
  }

  &_ButtonSection {
    display: flex;
    align-items: flex-end;
    height: 100%;

    > div:not(:last-child) {
      margin-right: 28px;
    }
  }

  &_Button {
    margin-bottom: 7px;
    border-radius: 10px;
    padding: 12px 32px;
    display: flex;
    align-items: center;
    font-weight: $font-bolder;

    background-color: $color-primary-100;
    color: $color-white;
    border: none;

    cursor: pointer;

    -moz-appearance: none;
    -webkit-appearance: none;

    &:focus {
      outline: none;
    }
    &:hover,
    &:focus {
      background-color: $color-primary-150;
    }

    &:disabled {
      background-color: $color-grey-50;
      color: $color-grey-25;
      cursor: not-allowed;
    }
  }
}
