@import '../../../assets/styles/_colors.scss';
@import '../../../assets/styles/_fonts.scss';

.TitleSection {
  padding-bottom: 23px;
  border-bottom: 1px solid $color-additional-light-blue;
  &_Title {
    font-size: $font-size-m;
    font-weight: $font-bold;
  }

  &_Button {
    font-weight: $font-bolder;
  }

  &_Total {
    margin: 18px 0 22px;
  }

  &_Border {
    > div:last-child {
      border-right: 1px solid $color-additional-light-blue;
    }
  }
}

.Column {
  display: flex;
  flex-direction: column;
  width: 100%;

  &_End {
    align-items: flex-end;
  }
}

.Row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
