@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.Total {
  width: 50%;
  &_Label {
    font-size: $font-size-xs;
  }

  &_Content {
    display: flex;
    align-items: flex-end;
    padding-top: 10px;
  }

  &_Value {
    font-size: $font-size-s;
    margin-right: 4px;
    font-weight: $font-bolder;
  }

  &_Units {
    font-size: $font-size-xxs;
    font-weight: $font-bold;
  }
}
