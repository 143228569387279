@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.EmptyContainers {
  display: flex;
  flex-direction: column;
  color: $color-dark-blue;
  justify-content: space-between;
  position: relative;
  flex-grow: 1;

  &_Main {
    display: flex;
    flex-direction: column;
    padding: 53px 0 0px 38px;
  }

  &_Title {
    font-size: $font-size-xl;
    margin-bottom: 16px;
  }

  &_Subtitle {
    font-size: $font-size-m;
    font-weight: $font-bold;
    color: $color-grey-100;
  }

  &_Image {
    position: absolute;
    bottom: 0;
    max-width: 500px;
    max-height: 300px;
  }
}
