@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.LinkButton{
  display: block;
  padding: 12px 22px;
  border-radius: 10px;
  border: solid 1px $color-white;
  color: $color-white !important;
  font-weight: 600;
  letter-spacing: 0.21px;
  font-size: $font-size-xs;
  transition: all .2s linear;
  &:hover{
   background-color: $color-white;
   color: $color-dark-blue !important;
  }
}
