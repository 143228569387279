@import '../../../../assets/styles/_colors.scss';
@import '../../../../assets/styles/_fonts.scss';

.InputWithControls {
  display: flex;
  background-color: $color-white;
  border: 1px solid $color-additional-light-grey-1;
  border-radius: 10px;

  &_Input {
    width: 32px;
    padding: 7px 0;
    border: 1px solid $color-additional-light-grey-1;
    background-color: $color-additional-light-grey-2;
    border-radius: unset;
    border: unset;
    text-align: center;

    &__Error {
      background-color: $color-error-bckg;
    }
  }

  &_Icon {
    > :first-child {
      width: 24px;
      height: 24px;
      fill: $color-primary-100;
    }
  }

  &__Error {
    border-color: $color-primary-150;
  }

  &_ControlL {
    border-right: 1px solid $color-additional-light-grey-1;

    &__Error {
      border-color: $color-primary-150;
    }
  }

  &_ControlR {
    border-left: 1px solid $color-additional-light-grey-1;

    &__Error {
      border-color: $color-primary-150;
    }
  }
}
