@import '../../../../assets/styles/_colors.scss';
@import '../../../../assets/styles/_fonts.scss';

.Radio {
  display: flex;

  &_Input {
    appearance: none;
    margin: 0;
  }

  &_DefaultLabel {
    color: $color-grey-100;
    display: flex;
    cursor: pointer;
    padding: 6px 20px;
    font-size: $font-size-xs;
    font-weight: $font-bold;

    .Radio_Input:hover + & {
      color: $color-primary-150;
    }
  }

  &_Selected {
    background-color: $color-primary-100;
    color: $color-white;
    border: 1px solid $color-primary-100;
    border-radius: 10px;

    .Radio_Input:focus + & {
      background-color: $color-primary-150;
      border-color: $color-primary-150;
      color: $color-white;
    }

    .Radio_Input:hover + & {
      background-color: $color-primary-150;
      border-color: $color-primary-150;
      color: $color-white;
    }
  }
}
