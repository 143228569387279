@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.TotalCounter {
  display: flex;
  flex-direction: column;
  color: $color-dark-blue;
  height: 100%;

  &_Label {
    font-size: $font-size-xxs;
    font-weight: $font-bold;

    &__Decreased {
      margin-bottom: 15px;
    }

    &__Scrolled {
      margin-bottom: 0;
    }
  }

  &_Row {
    display: flex;
    align-items: flex-end;

    &Value {
      font-size: $font-size-xxl;

      &__Decreased {
        font-size: $font-size-s;
      }

      &__Scrolled {
        font-size: $font-size-m;
      }
    }

    &Units {
      margin-left: 10px;
      margin-bottom: 6px;
      font-size: $font-size-s;

      &__Decreased {
        margin-bottom: 0;
      }

      &__Scrolled {
        margin-bottom: 0;
      }
    }
  }
}
