@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.BluePlateWithLink {
  margin: 0 auto;
  margin-top: -25px;
  position: relative;
  z-index: 1;
  width: 1002px;
  padding: 82px 64px;  
  background-color: $color-dark-blue;
  border-radius: 10px;
  box-shadow: 0 21px 64px 0 rgba(56, 61, 72, 0.05);  
  color: $color-white;
  &_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }
  &_text{
    width: 690px;
    line-height: 1.43;
    font-size: $font-size-xl;
  }
  &_graficsWrapper{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    path{
      width: 100%;
      height: 100%;
    }

  }
}
