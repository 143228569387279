@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.BenefitsCalc {
  padding-top: 150px;
  padding-bottom: 125px;
  background-color: $color-additional-light-grey-2;
  &_wrapper {
    max-width: 1290px;
    padding-right: 150px;
    display: flex;
    justify-content: space-between;
  }
  &_imageSide {
    width: 440px;
    height: 670px;
  }
}
