@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.ErrorIndicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;

  &_Text {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    align-items: center;
    font-size: 2rem;
    color: rgb(97, 97, 97);
  }
}
