@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.FloatingButton {
  padding: 8px;
  box-shadow: 0 3px 16px 0 rgba(150, 60, 57, 0.1);
  border-radius: 50%;
  width: 40px;
  height: 40px;

  position: fixed;
  bottom: 120px;
  right: calc(((100% - 1130px) / 2) - 56px);
}
