@import '../../../assets/styles/_colors.scss';
@import '../../../assets/styles/_fonts.scss';

.InputField {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  &_Label {
    display: block;
    color: $color-grey-100;
    font-size: $font-size-xxs;
    margin-bottom: 8px;
  }

  &_Input {
    padding: 7px 16px;
    font-size: $font-size-xs;
    box-sizing: border-box;
    width: 338px;
    background-color: $color-additional-light-grey-2;
    border: 1px solid $color-additional-light-grey-1;
    border-radius: 10px;
    height: 40px;

    &::placeholder {
      color: $color-grey-100;
    }

    &:focus,
    &:active {
      border-color: $color-dark-blue;
      outline: none;
    }

    &__Error {
      border-color: $color-primary-150;
      background-color: $color-error-bckg;

      &:focus,
      &:active {
        border-color: $color-dark-blue;
        outline: none;
      }
    }

    &ErrorText {
      &__Hidden {
        visibility: hidden;
        margin-bottom: 24px;
      }

      &__Visible {
        visibility: visible;
        color: $color-primary-150;
        font-size: $font-size-xxs;
        font-weight: $font-bold;
        margin-bottom: 10px;
      }
    }
  }
}
