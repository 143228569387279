@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.SpaceCalculatorLabel {
  position: relative;
  color: $color-white;

  &_Text {
    position: absolute;
    top: 30%;
    left: 10%;
  }

  &_Title {
    font-size: $font-size-xxl;
    margin-bottom: 16px;
  }

  &_Subtitle {
    font-size: $font-size-m;
  }
}
