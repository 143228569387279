@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.wrapper{
    &_ThreeDMainBox{
        position: absolute;    
        width: 100%;
        height: 100%;      
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);      
    }
    &_canvas{
        width: 100%;
        height: 100%;
    }
}