@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.SpaceCalculatorLanding {
  padding-top: 252px;
  padding-bottom: 130px;
  background-color: $color-dark-blue;
  position: relative;
  z-index: 1;
  &_integration {
    width: 1130px;
    margin: 0 auto;
    margin-top: 150px;
  }
  &_text {
    color: $color-white;
    width: 1015px;
    margin-bottom: 88px;
  }
  &_title {
    font-size: $font-size-xxxl;
    font-weight: $font-bolder;
    line-height: 1.3;
    margin-top: 0;
    margin-bottom: 24px;
    -webkit-font-smoothing: subpixel-antialiased;
  }
  &_subtitle {
    font-size: $font-size-xl;
    line-height: 1.43;
  }
  &_bgWrapper {
    position: absolute;
    margin-top: -45px;
    width: 600px;
    height: 740px;
    z-index: -1;
    right: 0;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
