@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.MultipleRangeWithValues {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;

  &_Percent,
  &_Value {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 15px;

    color: $color-grey-100;
    font-size: $font-size-xxs;
    font-weight: $font-bold;

    &Max,
    &Min {
      position: absolute;
    }
  }

  &_Percent {
    margin-bottom: 7px;
    margin-top: 5px;
  }

  &_Value {
    margin-top: 18px;
  }
}
