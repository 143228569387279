@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.Container3DVizualSmall {
  position: relative;
  height: 195px;
  border-radius: 8px;
  background-color: $color-additional-light-grey-2;
  &::before{
    position: absolute;
    content: '';
    z-index: 1;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;    
    border-radius: 8px;
  }
}
.callModalBtn{
  position: absolute;
  z-index: 2;
  width: 32px;
  height: 32px;
  border: none;
  top: 16px;
  right: 16px;
  padding: 0;
  background-color: transparent !important;
  border-radius: 0;
  transition: all .2s linear;
  &:hover{
    background-color: transparent;   
    transform:scale(1.05);
  }
}
.bigThreeDModal{
  height: 500px;
  width: 600px;
}
