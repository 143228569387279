@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.WarningMessage {
  display: flex;
  background-color: $color-warning-bck;
  color: $color-warning-text;
  font-size: $font-size-xs;
  font-weight: $font-bold;
  padding: 6px 20px;
  margin-bottom: 10px;
}
