@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.CreateLoadGroupItem {
  display: flex;
  flex-direction: column;

  background-color: $color-white;
  border: 1px solid $color-additional-light-grey-1;
  border-radius: 10px;

  width: 100%;

  &_Divider {
    border-bottom: 1px solid $color-additional-light-grey-1;
  }

  &_Label {
    color: $color-grey-100;
    display: block;
    font-size: $font-size-xxs;
    margin-bottom: 8px;
  }

  &_Value {
    display: flex;
    align-items: flex-end;
    color: $color-dark-blue;
    height: 24px;
    font-size: $font-size-xs;
    margin-bottom: 8px;
    padding-left: 2px;
  }
}
