@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.HomePageContent {
  width: 100%;
  background-image: url('/assets/images/home/img.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;
  padding-top: 40px;
  // position: relative;
  // overflow-y: hidden;
  overflow: visible;
  display: flex;
  flex-direction: column;
  color: $color-dark-blue;
  &_Main_Top {
    position: relative;
  }
  &_Main {
    flex-grow: 1;
    width: 555px;
    margin-left: 155px;
    margin-top: 60px;
    display: flex;
    flex-direction: column;

    &__Small {
      margin-top: 30px;
    }

    &__Big {
      margin-top: 90px;
    }
  }

  &_Title {
    font-size: $font-size-xxl;
    font-weight: $font-bolder;
    line-height: 1.3;
    margin-bottom: 48px;
    -webkit-font-smoothing: subpixel-antialiased;

    &__Small {
      margin-bottom: 28px;
      font-size: 36px;
    }

    &__Big {
      font-size: 56px;
    }
  }

  &_Options {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &_Option {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: $color-white;
    border: unset;
    box-sizing: border-box;
    width: 474px;
    height: 135px;
    padding: 30px 32px 25px;
    font-size: $font-size-xl;
    line-height: 1.43;
    font-weight: normal;
    z-index: 2;

    border-radius: 10px;
    box-shadow: 0 21px 64px 0 rgba(56, 61, 72, 0.05);

    &Text {
      margin-top: 16px;
    }

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    &:hover,
    :focus {
      cursor: pointer;
      color: $color-primary-150;
      border: 2px solid $color-primary-100;
      fill: $color-primary-100;
    }
  }

  &_White {
    position: absolute;
    bottom: -320px;
    width: 19%;
    height: 12%;
    width: 300px;
    height: 500px;
  }

  &_Red {
    position: absolute;
    bottom: -360px;
    right: 0;
    // width: 50%;
  }
}
