@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.Input {
  &_Container {
    position: relative;
  }

  -moz-appearance: textfield;
  &::-webkit-inner-spin-button {
    display: none;
  }
}
