@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.SizeSelection {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 2px;
  justify-content: flex-end;

  &_Content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    border-right: 1px solid $color-additional-light-blue;
    padding: 0 23px;

    > div:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &_Title {
    font-size: $font-size-m;
    font-weight: $font-bold;
    margin-bottom: 25px;
    margin-right: 23px;
  }

  &_InputWeight {
    margin-top: 12px;
  }

  &_Field {
    display: flex;
    font-size: $font-size-xs;
  }

  &_Label {
    font-weight: $font-bold;
    margin-right: 14px;
    font-size: $font-size-xxs;
    color: $color-grey-100;
  }

  &_Value {
    width: 90px;
    box-sizing: border-box;
    padding-left: 16px;
  }
}
