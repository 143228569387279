$font-default: 'WorkSans';
$font-bold: 500;
$font-bolder: 600;

$font-size-xxxl: 56px;
$font-size-xxl: 40px;
$font-size-xl: 28px;
$font-size-l: 20px;
$font-size-m: 18px;
$font-size-s: 16px;
$font-size-xs: 14px;
$font-size-xxs: 12px;

$work-sans-font-path: '../fonts/WorkSans';

@font-face {
  font-family: WorkSans;
  src: url('#{$work-sans-font-path}-Regular.ttf') format('opentype');
}
