@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.DeletePopup {
  box-shadow: 0 0 10px 2px rgba(84, 93, 120, 0.26);
  z-index: 5;
  position: absolute;
  color: $color-primary-100;
  right: 0;
  width: 120px;
  box-sizing: border-box;
  padding: 12px 16px;
  font-weight: $font-bolder;

  &:hover {
    color: $color-primary-100;
    background-color: $color-additional-light-grey-1;
  }
}
