@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.ToastesWithClose {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 40px;
  top: 270px;
  &_item {
    margin-bottom: 15px;
    position: relative;
  }
  &_subItem {
    position: static;
  }
  &_closeToast {
    margin-left: 16px;
    margin-right: -12px;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background-color: $color-warning-btn;
    position: relative;
    z-index: 1;
    padding-top: 8px;
    &:hover {
      background-color: $color-warning-btn !important;
    }
    &:focus {
      border: none;
    }
  }
  &_toastOnList {
    position: static;
    display: flex;
    align-items: center;
    align-content: center;
  }
  &_scroll {
    top: 210px;
  }
}
