@import '../../../../assets/styles/_colors.scss';
@import '../../../../assets/styles/_fonts.scss';

.SecondLineFormSection {
  display: flex;

  padding: 16px 40px 10px 16px;

  > div:last-child {
    flex-grow: 1;
  }

  &_Icon {
    width: 145px;
    height: 145px;
    margin-right: 20px;
  }

  &_Row {
    display: flex;
    justify-content: space-between;
  }

  &_MarginB {
    margin-bottom: 15px;
  }

  &_Column {
    display: flex;
    flex-direction: column;
  }

  &_Width {
    width: 272px;
  }

  &_DescriptionBlock {
    margin-right: 100px;
  }

  &_Content {
    display: flex;
    flex-grow: 1;

    > div:not(:last-child) {
      margin-right: 67px;
    }
  }

  &_DimensionsRow {
    display: flex;

    > div:not(:last-child) {
      margin-right: 16px;
    }
  }

  &_RadioContainer {
    background-color: $color-additional-light-grey-2;
    border: 1px solid $color-additional-light-grey-1;
    border-radius: 10px;
    overflow: hidden;
  }
}
