@import '../../../../assets/styles/_colors.scss';
@import '../../../../assets/styles/_fonts.scss';

.FirstLineFormSection {
  display: flex;
  justify-content: space-between;

  padding: 24px 24px 24px 16px;

  &_Row {
    display: flex;
    justify-content: space-between;

    > div:not(:last-child) {
      margin-right: 10px;
    }
  }

  &_Column {
    width: 491px;
    margin-right: 100px;
  }

  &_Weight {
    width: 105px;
  }

  &_Content {
    display: flex;
    flex-grow: 1;

    > div:not(:last-child) {
      margin-right: 20px;
    }
  }

  &_Icon {
    position: absolute;
    right: 24px;
  }
}
