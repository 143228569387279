@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.Spinner {
  display: flex;
  position: relative;
  height: 40px;
  width: 80px;

  &Container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.Spinner div {
  transform-origin: 40px 25px;
  animation: Spinner 1.2s linear infinite;
}
.Spinner div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 4px;
  height: 12px;
  border-radius: 20%;
  background: $color-dark-blue;
}
.Spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.Spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.Spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.Spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.Spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.Spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.Spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.Spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.Spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.Spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.Spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.Spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes Spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
