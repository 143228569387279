@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.Delete {
  &_Icon {
    fill: $color-grey-50;

    &:hover {
      fill: $color-grey-100;
    }
  }

  &_Button {
    margin-top: 14px;
    margin-left: 20px;
    position: relative;
  }
}
