@import '../../../../assets/styles/_colors.scss';
@import '../../../../assets/styles/_fonts.scss';

.Radio {
  display: flex;
  align-items: center;
  height: 45px;
  cursor: pointer;
  font-size: $font-size-xs;
  font-weight: $font-bold;
  color: $color-dark-blue;

  &:last-child {
    .Radio_Label {
      border-bottom: none;
    }
  }

  &_Line {
    visibility: hidden;
    width: 4px;
    height: 100%;

    &_Selected {
      visibility: visible;
      background-color: $color-primary-100;
    }

    .Radio:hover & {
      background-color: $color-primary-150;
    }
  }

  &_Input {
    appearance: none;
    margin: 0;
  }

  &_Icon {
    width: 24px;
    height: 24px;
    fill: $color-dark-blue;

    margin-left: 10px;

    .Radio:hover > .Radio_LabelRow > & {
      fill: $color-primary-150;
    }
  }

  &_Label {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 12px 0;
    flex-grow: 1;
    margin: 0 7px;
    border-bottom: 1px solid $color-additional-light-blue;
  }

  &_LabelRow {
    display: flex;
    width: 100%;
    align-items: center;
    cursor: pointer;

    .Radio:hover & {
      color: $color-primary-150;
    }
  }

  &:hover {
    color: $color-primary-150;
  }

  &_Selected {
    color: $color-primary-100;
    fill: $color-primary-100;

    .Radio:focus + & {
      color: $color-primary-150;
    }

    .Radio & > .Radio_Icon {
      fill: $color-primary-100;
    }

    .Radio:hover + & {
      color: $color-primary-150;
    }

    .Radio:hover & > .Radio_Icon {
      fill: $color-primary-150;
    }
  }
}
