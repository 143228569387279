@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.cardListWrapper {
  max-width: 1360px;
  margin: 0 auto;
  margin-top: -354px;

  &_CardList {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    list-style: none;
  }
}
