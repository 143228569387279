@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.Tooltip {
  background-color: $color-white;
  border-radius: 10px;
  padding: 12px 15px;
  position: absolute;
  z-index: 12;
  word-break: break-word;

  box-shadow: 0 0 10px 2px rgba(84, 93, 120, 0.26),
    2px 10px 14px 0 rgba(90, 92, 101, 0.12);

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -7px;
    border-width: 7px;
    border-style: solid;
    border-color: $color-white transparent transparent transparent;
  }
}
