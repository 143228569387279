@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.BenefitsCalculatorListItems {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &_listItem {
    width: 40%;
    margin-bottom: 64px;
  }
  &_iconWrapper {
    margin-bottom: 16px;
    width: 32px;
    height: 32px;
    svg {
      width: 32px;
      height: 32px;
    }
  }
  &_text {
    font-size: $font-size-s;
    line-height: 1.5;
  }
}
