@import '../../../../assets/styles/_colors.scss';
@import '../../../../assets/styles/_fonts.scss';

.NameField {
  display: flex;
  align-items: center;
  &_Color {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--loadGroup-color-variable);
  }

  &_Input {
    padding: 7px 16px;
    color: $color-dark-blue;
    font-weight: normal;
    font-size: $font-size-xl;
    caret-color: $color-dark-blue;

    border: none;

    &::placeholder {
      color: $color-grey-25;
    }

    &:focus,
    &:active {
      border-color: $color-dark-blue;
      outline: none;
    }

    &__Error {
      caret-color: $color-primary-150;
      border-bottom: 1px solid $color-primary-150;

      &:focus,
      &:active {
        border-bottom: 1px solid $color-primary-150;
        outline: none;
      }
    }
  }
}
