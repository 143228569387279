@import '../../../../assets/styles/_colors.scss';
@import '../../../../assets/styles/_fonts.scss';

.Radio {
  display: flex;

  &_Input {
    appearance: none;
    margin: 0;
  }

  &_Emoji {
    font-size: $font-size-l;
    margin-right: 10px;
  }

  &_DefaultLabel {
    color: $color-dark-blue;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 16px;
    font-size: $font-size-xs;
    width: 100%;

    border: 1px solid $color-additional-light-grey-1;
    border-radius: 10px;

    .Radio_Input:hover + & {
      color: $color-primary-150;
    }
  }

  &_Selected {
    border-color: $color-primary-150;
  }
}
