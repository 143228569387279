@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.Toast {
  position: fixed;
  right: 40px;
  display: flex;
  width: 325px;
  height: 80px;
  overflow: hidden;
  box-sizing: border-box;
  color: $color-dark-blue;
  font-size: $font-size-xs;

  background-color: $color-white;
  border-radius: 10px;
  box-shadow: 0 0 10px 2px rgba(84, 93, 120, 0.26),
    2px 10px 14px 0 rgba(90, 92, 101, 0.12);

  &_Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px 20px;
  }

  &_Line {
    width: 4px;
    height: 100%;
    background-color: $color-toast-bck;
  }

  &_Title {
    font-weight: $font-bold;
    margin-bottom: 8px;
  }

  &_Subtitle {
    color: $color-grey-100;
  }
}
