@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.SpaceOptimization {
  padding: 0 24px;
  box-sizing: border-box;
  width: 100%;

  &_Title {
    font-size: $font-size-m;
    font-weight: $font-bold;
    margin-bottom: 25px;
  }

  &_RangeContainer {
    display: flex;
    flex-direction: column;

    > div:first-child {
      margin-bottom: 22px;
    }
  }
}
