@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.NotFoundPageContent {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 100%;

  &_Image {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &_Text {
    color: $color-dark-blue;
    font-size: $font-size-xxl;
    margin-bottom: 28px;
  }

  &_Button {
    font-weight: $font-bolder;
    font-size: $font-size-xs;
  }
}
