@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.Skeleton {
  display: flex;
  flex-direction: column;
  padding: 23px 24px 12px 40px;
  background-color: $color-white;
  box-sizing: border-box;

  &_Content {
    max-width: 1000px;
  }

  &_Column {
    display: flex;
    flex-direction: column;
  }

  &_Animation {
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: -150px;
      top: 0;
      width: 150px;
      background: linear-gradient(
        to right,
        transparent 0%,
        $color-skeleton-bck 50%,
        transparent 100%
      );
      animation: load 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
  }

  &_Row {
    display: flex;
  }

  &_Space {
    max-width: 384px;
    margin-right: 54px;
    &Title,
    &Text,
    &Image {
      background-color: $color-skeleton-bck;
    }

    &Title {
      max-width: 188px;
      min-width: 150px;
      height: 17px;
      margin-bottom: 30px;
    }

    &Text {
      max-width: 188px;
      min-width: 150px;
      height: 42px;

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
    &Image {
      max-width: 192px;
      min-width: 155px;
      height: 183px;
      margin-left: 6px;
    }
  }

  &_Size {
    width: 30%;
    max-width: 282px;
    margin-right: 60px;
    &Title,
    &Text {
      background-color: $color-skeleton-bck;
    }
    &Title {
      height: 17px;
      margin-bottom: 30px;
    }

    &Text {
      height: 28px;

      &:not(:last-child) {
        margin-bottom: 11px;
      }
    }
  }

  &_Optimization {
    width: 30%;
    max-width: 335px;
    &Title,
    &Range {
      background-color: $color-skeleton-bck;
    }
    &Title {
      height: 17px;
      margin-bottom: 68px;
    }
    &Range {
      height: 67px;

      &:not(:last-child) {
        margin-bottom: 11px;
      }
    }
  }

  &_Button {
    background-color: $color-skeleton-bck;
    width: 140px;
    height: 40px;
    margin-top: 15px;
  }
}

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}
