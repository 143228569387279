@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.Policies {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-white;
  height: 120px;
  padding: 40px;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  z-index: 30;
  width: 100%;

  &_Text {
    width: 670px;
    color: $color-dark-blue;
    font-size: $font-size-s;
    line-height: 1.5;
  }

  &_Buttons {
    display: flex;

    > :first-child {
      margin-right: 20px;
    }
  }

  &_PrimaryBtn {
    font-size: $font-size-xs;
    font-weight: $font-bolder;
    padding: 12px 82px;
    box-sizing: border-box;
  }

  &_LinkBtn {
    padding: 12px 23px;
    box-sizing: border-box;
    font-size: $font-size-xs;
    font-weight: $font-bolder;
  }
}
