@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.Select {
  height: 32px;

  width: 190px;
  position: relative;

  &_Native {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(../../assets/images/icon-selectArrow.svg);
    background-repeat: no-repeat;
    background-position: right 50%;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    border: solid 1px $color-additional-light-grey-1;
    padding: 4px 8px 4px 16px;
    background-color: $color-white;
    border-radius: 10px;

    color: $color-dark-blue;

    &:focus {
      border: solid 1px $color-dark-blue;
      outline: none;
    }
  }

  &_Container {
    display: flex;
    flex-direction: column;
    color: $color-dark-blue;
    height: 100%;
  }

  &_Label {
    font-size: $font-size-xxs;
    margin-bottom: 8px;
    color: $color-dark-blue;
  }
}
