@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.SpaceForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: $color-white;
  height: 322px;
  color: $color-dark-blue;
  box-sizing: border-box;

  border-bottom: 1px solid $color-additional-light-grey-1;
  padding: 22px 0 14px 38px;

  &_SpaceSection {
    width: 384px;
  }

  &_Button {
    font-size: $font-size-s;
    border-radius: 10px;
    padding: 10px 32px;
    display: flex;
    align-items: center;
    font-weight: $font-bold;

    background-color: $color-primary-100;
    color: $color-white;
    border: none;

    cursor: pointer;

    -moz-appearance: none;
    -webkit-appearance: none;

    &:focus {
      outline: none;
    }
    &:hover,
    &:focus {
      background-color: $color-primary-150;
    }

    &:disabled {
      background-color: $color-grey-100;
      cursor: not-allowed;
    }
  }
}

.Column {
  display: flex;
  flex-direction: column;
}

.Row {
  display: flex;
  margin-bottom: 15px;
  width: 100%;
}
