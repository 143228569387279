@import '../../../assets/styles/_colors.scss';
@import '../../../assets/styles/_fonts.scss';

.RangesField {
  height: 75px;
  position: relative;
  width: 100%;

  &_Label {
    color: $color-grey-100;
    font-size: $font-size-xs;
  }

  &_Row {
    display: flex;
    align-items: center;
    position: relative;

    font-size: $font-size-xs;
    font-weight: $font-bold;
  }

  &_Icon {
    fill: $color-grey-100;
    width: 16px;
    height: 16px;
    margin-left: 8px;

    &:hover {
      cursor: pointer;
      fill: $color-primary-100;
    }
  }

  &_Tooltip {
    visibility: hidden;
    opacity: 0;
    width: 265px;
    left: calc(50% + 25px);
    cursor: pointer;
    font-size: $font-size-xxs;
    color: $color-dark-blue;
    font-weight: normal;
    &::after {
      left: 1px;
      top: calc(50% - 6.5px);
      transform: translateX(-50%) rotate(90deg);
    }

    .RangesField_Icon:hover + & {
      visibility: visible;
      opacity: 1;
    }

    &__TextBold {
      font-weight: $font-bolder;
      color: $color-primary-100;
    }
  }
}
