@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.SpaceSelection {
  display: flex;
  flex-direction: column;

  &_Content {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $color-additional-light-blue;
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    height: 182px;

    > div {
      width: 50%;
    }

    > div:not(:last-child) {
      border-right: 1px solid $color-additional-light-blue;
    }
  }

  &_Title {
    font-size: $font-size-m;
    font-weight: $font-bold;

    margin-bottom: 25px;
  }

  &_Containers {
    display: flex;
    flex-direction: column;
  }

  &__Image {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-image: linear-gradient(to top, #f4f6fa, rgba(255, 255, 255, 0));
  }
}
