@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.CalculationResultsTitle {
  display: flex;
  flex-direction: column;
  padding: 24px 24px 0;

  &_Title {
    color: $color-dark-blue;
    font-size: $font-size-xl;
  }

  &_TitleRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }

  &_Button {
    font-size: $font-size-xs;
    font-weight: $font-bolder;
    padding: 12px 26px;
    box-sizing: border-box;
    height: 40px;
  }
}
