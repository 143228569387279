@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  z-index: 99;
  background-color: $color-dark-blue;
  color: $color-white;
  min-height: 40px;
  padding: 0 40px;
}
