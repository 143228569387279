@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.ContainersListItem {
  display: flex;
  flex-direction: column;

  background-color: $color-white;
  border-radius: 10px;
  border: solid 1px $color-additional-light-grey-1;
  min-height: 216px;
  color: $color-dark-blue;
  height: min-content;

  &_Title {
    font-size: $font-size-m;
    padding: 20px;
    padding-bottom: 8px;
    font-weight: $font-bold;
  }
  &_Quantity {
    padding-left: 20px;
    padding-bottom: 11px;
    font-size: $font-size-xxs;
    color: $color-grey-100;
  }

  &_Content {
    padding: 0px 20px 15px;
  }

  &_Divider {
    width: 100%;
    height: 1px;
    background-color: $color-additional-light-grey-1;
    margin-top: 4px;
    margin-bottom: 32px;
  }

  &_OpenBtn {
    font-size: $font-size-xs;
    height: 24px;
  }
}
