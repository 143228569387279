@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.Modal {
  display: flex;
  background-color: $color-white;
  box-shadow: 0 0 10px 2px rgba(84, 93, 120, 0.26),
    2px 10px 14px 0 rgba(90, 92, 101, 0.12);
  border-radius: 10px;
  z-index: 20;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 20px;
  width: 440px;
  color: $color-dark-blue;
  box-sizing: border-box;

  &_Content {
    padding: 24px 28px 20px;
    width: 100%;
  }

  &_CloseBtn {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &_Title {
    font-size: $font-size-xl;
    margin-bottom: 30px;
  }

  &_Subtitle {
    margin-bottom: 20px;
    font-size: $font-size-xs;
  }
}
.ThreeDModalStyleContainer{
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 20;
    height: 100%;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.5);

}
