@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.ListDataWrrapper {
  width: 1130px;
  margin: 0 auto;
  &_List{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;
  }
}
