@import '../../../assets/styles/_colors.scss';
@import '../../../assets/styles/_fonts.scss';

.MultipleRange {
  &_Thumb,
  &_Thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  &_Thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 287px;
    outline: none;

    @mixin thumb-overrides {
      background-color: $color-white;
      border: 1px solid $color-additional-light-grey-1;
      border-radius: 50%;
      box-shadow: 0 2px 4px 0 rgba(148, 155, 176, 0.31);
      cursor: pointer;
      height: 16px;
      margin-top: 4px;
      width: 16px;
      pointer-events: all;
      position: relative;
    }

    &::-webkit-slider-thumb {
      @include thumb-overrides();
    }
    &::-moz-range-thumb {
      @include thumb-overrides();
    }

    &Right::-webkit-slider-thumb {
      z-index: 4;
    }

    &Left::-webkit-slider-thumb {
      z-index: 3;
    }
  }

  &_Slider {
    position: relative;
    width: 287px;

    &Track,
    &Range {
      border-radius: 2px;
      border: 1px solid $color-additional-light-grey-1;
      height: 6px;
      position: absolute;
    }

    &Track {
      background-color: $color-bar-bck;
      width: 100%;
      z-index: 1;
    }

    &Range {
      background-image: linear-gradient(to left, #be403b, #ffa7a4);
      z-index: 2;
    }
  }
}
