@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.InfoMessage {
  display: flex;
  margin: 24px 0;

  &_Title {
    font-size: $font-size-xxs;
    color: $color-primary-150;
    font-weight: $font-bold;
    margin-bottom: 6px;
  }

  &_Subtitle {
    color: $color-grey-100;
  }

  &_Text {
    display: flex;
    flex-direction: column;
    font-size: $font-size-xxs;
    margin-top: 6px;
  }

  &_Icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    fill: $color-grey-100;

    > :first-child {
      fill: $color-primary-150;
    }
  }
}
