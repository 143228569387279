@import '../../../assets/styles/_colors.scss';
@import '../../../assets/styles/_fonts.scss';

.CountersRow {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-grow: 1;

  &__Decreased {
    height: 100%;
  }

  &__Scrolled {
    justify-content: flex-start;
    height: unset;
  }

  > div:not(:last-child) {
    margin-right: 44px;
  }
}
