@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.SuccessModalContent {
  &_ButtonContainer {
    margin-top: 12px;
  }

  &_Button {
    font-weight: $font-bolder;
  }
}
