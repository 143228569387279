@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.CreateLoadFormContainer {
  &_Content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &_MainContent {
    width: 1130px;
    padding-top: 24px;
  }

  &_Button {
    margin-top: 88px;
  }

  &_Loader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
  }
}
