@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.SendResultsForm {
  &_Loader {
    min-height: 203px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_Button {
    font-size: $font-size-s;
    border-radius: 10px;
    padding: 10px 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: $font-bolder;
    width: 100%;

    background-color: $color-primary-100;
    color: $color-white;
    border: none;

    cursor: pointer;

    -moz-appearance: none;
    -webkit-appearance: none;

    &:focus {
      outline: none;
    }
    &:hover,
    &:focus {
      background-color: $color-primary-150;
    }

    &:disabled {
      background-color: $color-grey-100;
      cursor: not-allowed;
    }
  }
}
