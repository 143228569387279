@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.Error500 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  overflow-y: hidden;

  &_ImageCat {
    position: absolute;
    right: 0;
    bottom: 10%;
    width: 20%;
    height: 45%;
  }

  &_ImageBoxS {
    position: absolute;
    right: 50px;
    top: 5%;
    width: 10%;
    height: 10%;
    transform: rotate(-20deg);
  }

  &_ImageBoxM {
    position: absolute;
    left: 0;
    top: 45%;
    width: 40%;
    height: 15%;
    transform: rotate(18deg);
  }

  &_ImageBoxL {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 15%;
    height: 25%;
    transform: rotate(30deg);
  }

  &_Text {
    color: $color-dark-blue;
    font-size: $font-size-xxl;
    margin-bottom: 28px;
  }
}
