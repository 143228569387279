@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.Footer {
  width: 100%;
  height: 100px;
  background-color: $color-white;
  position: fixed;
  bottom: 0;
  outline: 1px solid $color-additional-light-grey-1;
  z-index: 10;
  box-shadow: 0 0 10px 2px rgba(84, 93, 120, 0.26);

  box-sizing: border-box;
  min-height: 100px;

  display: flex;
  justify-content: center;

  &_Content {
    max-width: 1130px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    box-sizing: border-box;
  }

  &_Subtitle {
    font-size: 10px;
    color: $color-grey-100;
    font-weight: $font-bold;
    margin: 8px 0;
  }

  &_Social {
    display: flex;

    > :not(:last-child) {
      margin-right: 8px;
    }
  }

  &_Block {
    display: flex;
    &Logo {
      display: flex;
      flex-direction: column;
    }

    &Links {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      margin: 0 58px;
    }

    &Contact {
      display: flex;
      font-weight: $font-bolder;
      padding: 7px 20px;
      height: 30px;
      box-sizing: border-box;
    }
  }

  a {
    color: $color-dark-blue;
  }
}
