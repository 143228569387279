@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.BackButton {
  width: 1130px;
  display: flex;
  align-items: center;
  margin-top: 24px;

  &_Button {
    font-size: $font-size-xs;
    color: $color-dark-blue;
    letter-spacing: 0.21px;
  }

  &_Text {
    font-weight: $font-bolder;
  }

  &_Icon {
    width: 24px;
    height: 24px;
    margin-right: 4px;

    > :first-child {
      fill: $color-dark-blue;

      .BackButton_Button:hover & {
        fill: $color-primary-150;
      }
    }
  }
}
