@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.Button {
  border-radius: 10px;
  padding: 12px 32px;
  display: flex;
  align-items: center;

  &_TitleWithIcon {
    display: flex;
    align-items: center;
    fill: $color-dark-blue;
  }

  &_Primary {
    background-color: $color-primary-100;
    color: $color-white;
    border: none;

    &:hover,
    &:focus {
      background-color: $color-primary-150;
    }

    &:disabled {
      background-color: $color-grey-50;
      cursor: not-allowed;
    }
  }

  &_Outlined {
    background-color: $color-white;
    border: 1px solid $color-dark-blue;
    color: $color-dark-blue;
    height: 30px;
    padding: 10px 7px;
    font-size: $font-size-xs;
    letter-spacing: 0.2px;

    &:focus {
      border: solid 1px $color-primary-150;
      outline: none;
      color: $color-primary-150;
      background-color: $color-white;
    }
    &:hover {
      color: $color-primary-150;
      background-color: $color-white;
      border: solid 1px $color-primary-150;
    }
    &:disabled {
      color: $color-grey-100;
      background-color: $color-white;
      border: solid 1px $color-grey-100;
    }
  }

  &_Simple {
    background-color: $color-white;
    color: $color-primary-100;
    padding: 11px 32px;
    height: 40px;
    border: none;

    > .Button_TitleWithIcon {
      fill: $color-primary-100;
    }

    &:focus {
      border: none;
      outline: none;
      color: $color-primary-150;
      background-color: $color-white;

      > .Button_TitleWithIcon {
        fill: $color-primary-150;
      }
    }
    &:hover {
      color: $color-primary-150;
      background-color: $color-white;

      > .Button_TitleWithIcon {
        fill: $color-primary-150;
      }
    }
    &:disabled {
      color: $color-grey-100;
      background-color: $color-white;

      > .Button_TitleWithIcon {
        fill: $color-grey-100;
      }
    }
  }

  &_Link {
    background-color: transparent;
    color: $color-primary-100;
    padding: unset;
    border: none;
    font-size: $font-size-xs;
    font-weight: $font-bold;

    > .Button_TitleWithIcon {
      fill: $color-primary-100;
    }

    &:focus {
      outline: none;
      color: $color-primary-150;

      > .Button_TitleWithIcon {
        fill: $color-primary-150;
      }
    }
    &:hover {
      color: $color-primary-150;

      > .Button_TitleWithIcon {
        fill: $color-primary-150;
      }
    }
    &:disabled {
      color: $color-grey-100;

      > .Button_TitleWithIcon {
        fill: $color-grey-100;
      }
    }
  }

  &_Control {
    background-color: $color-white;
    color: $color-primary-100;
    padding: 4px;
    width: 30px;
    height: 30px;
    border: none;

    &:focus {
      border: solid 1px $color-dark-blue;
      outline: none;
      color: $color-primary-150;
      background-color: $color-white;
    }
    &:hover {
      color: $color-primary-150;
      background-color: $color-white;
    }
    &:disabled {
      color: $color-grey-100;
      background-color: $color-white;
    }
  }

  &_Fluid {
    width: 100%;

    &:hover {
      box-shadow: 0 3px 16px 0 rgba(34, 41, 62, 0.15);
    }

    &:disabled {
      box-shadow: none;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  cursor: pointer;

  -moz-appearance: none;
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }
}
