@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.TextWithColor {
  display: flex;

  > div:first-child {
    margin-right: 10px;
  }

  &_Color {
    flex-shrink: 0;
    margin-top: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--loadGroup-color-variable);
  }

  &_Name {
    display: flex;
    flex-wrap: wrap;
    word-break: break-word;
  }
}
