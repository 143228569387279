@import '../../../assets/styles/_colors.scss';
@import '../../../assets/styles/_fonts.scss';

.ContainerLoadsList {
  display: flex;
  flex-direction: column;

  &_Head {
    margin: 25px 0 16px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    color: $color-grey-100;
    font-size: $font-size-xxs;
    font-weight: $font-bold;

    > div:not(:first-child) {
      text-align: end;
    }
  }

  &_Item {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 16px 0;
    color: $color-dark-blue;
    font-size: $font-size-s;
    font-weight: normal;

    > div:not(:first-child) {
      text-align: end;
    }

    &Container {
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        border-bottom: 1px solid $color-additional-light-grey-2;
      }
    }

    :hover {
      cursor: pointer;
    }

    &__Hovered {
      font-weight: $font-bolder;
    }

    &Description {
      margin-bottom: 8px;
      color: $color-grey-100;
      font-size: $font-size-xxs;
      font-weight: $font-bold;
      word-break: break-all;
    }
  }
}
