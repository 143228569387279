@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.LoadGroupsList {
  width: 27%;
  background-color: $color-white;
  padding: 36px 40px;
  color: $color-dark-blue;
  font-weight: $font-bold;
  box-sizing: border-box;
  min-height: 100%;
  border-left: 1px solid $color-additional-light-grey-1;

  &_Head {
    border-bottom: 1px solid $color-additional-light-blue;
  }

  &_Main {
    margin-top: 26px;
  }

  &_Table {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    color: $color-grey-100;
    font-size: $font-size-xxs;
    font-weight: $font-bold;
    margin-bottom: 7px;
    column-gap: 10px;

    > div:not(:first-child) {
      text-align: end;
    }
  }
}

.Row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Column {
  display: flex;
  flex-direction: column;
  width: 100%;

  &_End {
    align-items: flex-end;
  }
}
