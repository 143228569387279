@import '../../../assets/styles/_colors.scss';
@import '../../../assets/styles/_fonts.scss';

.Field {
  padding: 7px 16px;
  font-size: $font-size-xs;
  box-sizing: border-box;

  background-color: $color-additional-light-grey-2;
  border: 1px solid $color-additional-light-grey-1;
  border-radius: 10px;

  &::placeholder {
    color: $color-grey-100;
  }

  &:focus,
  &:active {
    border-color: $color-dark-blue;
    outline: none;
  }

  &__Error {
    border-color: $color-primary-100;
    background-color: $color-error-bckg;
  }

  &_Label {
    display: block;
    color: $color-grey-100;
    font-size: $font-size-xxs;
    margin-bottom: 8px;
  }

  &_WidthDefault {
    width: 90px;
    max-width: 100px;
  }
}
