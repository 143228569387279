@import '../assets/styles/_colors.scss';
@import '../assets/styles/_fonts.scss';

.Page {
  flex-grow: 1;
  padding: 40px 0 100px;
  position: relative;
  &_ToastWarrning {
    position: fixed;
    margin-top: 110px;
    right: 40px;
    width: 325px !important;
    display: inline-flex;
    justify-content: flex-end;
    z-index: 2;
  }

  &_ThreeDContainer {
    position: relative;
    height: 100%;
  }
  &_ThreeDContainer_big {
    margin-top: 10px;
    height: 420px;
    width: 500px;
    position: relative;
    z-index: 20;
    border-radius: 8px;
    background-color: $color-additional-light-grey-2;
  }

  &_Loader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }

  &_LoadGroups,
  &_Home {
    display: flex;
    justify-content: center;
  }
  &_Home {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &_Home {
    padding-top: unset;
    background-color: $home-page-bck;

    &__Empty {
      overflow-y: hidden;
    }
  }

  &_LoadGroups {
    &__Toast {
      top: 175px;

      &Scrolled {
        top: 115px;
      }
    }
  }

  &_Container {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;

    > div:first-child {
      width: 73%;
    }

    &Content {
      display: flex;
      flex-direction: column;

      > div:last-child {
        flex-grow: 1;
      }
    }

    &__ModalClosed {
      display: none;
    }

    &__ModalOpened {
      display: flex;
      position: fixed;
      width: 100%;
      z-index: 20;
      height: 100%;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }

  &_404 {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  // TODO change it when 3D calculation will be ready
}
