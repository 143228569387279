@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.EmptyLoadsList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;

  color: $color-dark-blue;

  &Title {
    font-size: $font-size-xl;
  }

  &Text {
    margin-top: 8px;
    margin-bottom: 27px;
  }

  &Image {
    > :first-child {
      width: 300px;
      height: 300px;
    }
  }
}
