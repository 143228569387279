$color-primary-150: #c66460;
$color-primary-100: #ea6661;
$color-primary-75: #e88582;
$color-primary-50: #e7a5a2;
$color-primary-25: #e5c4c3;

$color-additional-light-blue: #e0e5f2;
$color-additional-light-grey-2: #f8f8f8;
$color-additional-light-grey-1: #f0f1f3;

$color-grey-100: #727784;
$color-grey-50: #abadb4;
$color-grey-25: #c7c9cc;
$color-white: #fff;

$color-error-bckg: #f6e6e5;
$color-skeleton-bck: #f9dede;
$color-dark-blue: #22293e;
$home-page-bck: #f4f3f1;
$color-toast-bck: #e02854;
$color-bar-bck: #f1f6ff;
$color-warning-bck: #fff0c9;
$color-warning-text: #c37731;
$color-warning-btn: #fce8e7;
