@import 'assets/styles/_colors.scss';
@import 'assets/styles/_fonts.scss';

.App {
  width: 100%;
  display: flex;
  height: 100vh;
  flex-direction: column;
}

html {
  height: 100vh;
}

body {
  margin: 0;
  width: 100%;
  height: 100vh;

  font-family: $font-default, sans-serif;
  background-color: $color-additional-light-grey-2;
  -webkit-text-stroke-width: 0 !important;
  -webkit-font-smoothing: antialiased;

  // > div {
  //   width: 100%;
  // }
}

*,
button,
input,
select,
textarea {
  font-family: $font-default, sans-serif;
}

a {
  text-decoration: none;

  &:visited {
    color: initial;
  }

  &:hover {
    color: $color-primary-150;
  }
}
