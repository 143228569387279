@import '../../../../assets/styles/_colors.scss';
@import '../../../../assets/styles/_fonts.scss';

.Field {
  padding: 7px 16px;
  font-size: $font-size-xs;
  box-sizing: border-box;
  height: 30px;

  background-color: $color-additional-light-grey-2;
  color: $color-dark-blue;
  border: 1px solid $color-additional-light-grey-1;
  border-radius: 10px;

  &_Container {
    display: flex;
    align-items: center;
  }

  &::placeholder {
    color: $color-grey-100;
  }

  &:focus,
  &:active {
    border-color: $color-dark-blue;
    outline: none;
  }

  &__Error {
    border-color: $color-primary-150;
    background-color: $color-error-bckg;
  }

  &_Label {
    display: block;
    font-weight: $font-bold;
    color: $color-grey-100;
    font-size: $font-size-xxs;
    margin-right: 14px;
  }

  &_WidthDefault {
    width: 90px;
    max-width: 100px;
  }
}
