@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.BenefitsCalculatorList {
  max-width: 55%;
  background-color: $color-additional-light-grey-2;
  &_title {
    margin-top: 0;
    margin-bottom: 55px;
    color: $color-dark-blue;
    font-size: $font-size-xxxl;
    font-weight: $font-bolder;
    line-height: 1.3;
    -webkit-font-smoothing: subpixel-antialiased;
  }
}
