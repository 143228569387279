@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_fonts.scss';

.CreateLoadGroupList {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 19px;

  > div {
    display: flex;
    align-items: flex-start;
    margin-bottom: 19px;
  }

  &_Empty {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }

  &_Content {
    position: relative;
  }
}
